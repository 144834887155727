import React from "react";
import { DEFAULT_SECTION, SEARCH_API } from "../../utils/config_data";
import { useRef } from "react";

const QueryBox = ({
  loader,
  setLoading,
  setPresent,
  setResults,
  results,
  inpRef,
}) => {
  const controllerRef = useRef();

  const getResults = async (e) => {
    e.preventDefault();
    const query = inpRef.current.value;
    if (query.trim() === "") {
      return;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();
    let signal = controllerRef.current.signal;

    const url = SEARCH_API + "?search=" + query;
    try {
      const config = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Connection: "keep-alive",
        },
        signal: signal,
      };
      setLoading(true);
      setPresent(DEFAULT_SECTION);
      let response = await fetch(url, config);
      if (response.ok) {
        response = await response.json();
        setResults(response.labs);
      } else {
        console.log(response);
      }
      // console.log(response);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className=" container-header flex flex-row p-2 h-14">
        <div className="section-type view-box-link">Lab Search</div>
      </div>
      <div className="flex w-full pt-0">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          className="form w-full flex flex-col"
        >
          <div className="flex flex-row w-full">
            <input
              type="text"
              className="search-query w-input"
              maxLength="256"
              name="name"
              data-name="Name"
              placeholder="Enter lab name..."
              id="name"
              ref={inpRef}
              onChange={getResults}
            />
            <input
              type="submit"
              value="Search"
              className="submit-button w-button w-32"
              onClick={getResults}
            />
          </div>
        </form>
      </div>
      <div className="flex m-2 mb-1">
        {results?.length === 0
          ? "No results"
          : results?.length + (results?.length === 1 ? " result" : " results")}
      </div>
    </>
  );
};

export default QueryBox;
